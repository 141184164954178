
import { PopoutsModule } from "@/store/modules/popouts";
import CustomButton from "@/components/CustomButton.vue";
import InformationTemplate from "@/components/InformationTemplate.vue";
import AgreementMessage from "@/components/AgreementMessage.vue";
import { AgreePolicy } from "@/services/authService";

import { defineComponent } from "vue";
export default defineComponent({
  name: "ProfilePopout",
  components: { CustomButton, InformationTemplate, AgreementMessage },
  props: {
    identity: {
      type: String,
      required: true,
    },
  },
  methods: {
    close() {
      PopoutsModule.ClosePopout(this.identity);
    },
    deleteAccount() {
      const w = window.open(
        "mailto:nertivia@gmail.com?subject=Delete%20My%20Account",
        "_blank"
      );
      w?.focus();
    },
    agree() {
      AgreePolicy().then(() => {
        this.$socket.connect();
        this.close();
      });
    },
  },
});
